import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import Header from "../../Components/Header";
import { autoMoneyTransfer, getOptionSubAdmin, getTradingPlans, getUserSubscription, getWithdrawal, restRecord, updateUserSubscription, updateWithdrawal } from "../../reducers/commonReducer";
import moment from 'moment';
import WithdrawalAction from "./UserSubscriptionAction";
import SideBaar from "../../Components/SideBaar";
import UserSubscriptionAction from "./UserSubscriptionAction";

const UserSubscriptionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [startDate, setstartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00'));
  // const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [status, setStatus] = useState();
  const [userName, setUserName] = useState();
  const [amount, setAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [subadmin, setSubadmin] = useState();
  const [tradingPlanId, setTradingPlanId] = useState();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getTradingPlans())
    dispatch(restRecord());
    dispatch(getUserSubscription({page: currentPage}))
    // dispatch(getWithdrawal({currentPage, end:endDate, start:startDate,}))
    // if(localStorage.getItem("userRole") === "1"){
    //   dispatch(getOptionSubAdmin());
    // }
  }, [navigate, dispatch]);
  const {userSubscriptionData, tradingPlans, getWithdrawalList, getOptionSubAdminList, userSubscriptionDataBlank, totalAmount} = useSelector((state) => state.commonReducer);
  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(getUserSubscription({
      amount, 
      // end:endDate, start:startDate, 
      status, userid: userName, subadmin, page:currentPage, tradingPlanId : tradingPlanId
    }))
  }
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-4 user-table">
          <Col md={6}>
            <h3 className="mb-0">User Subscription</h3>
          </Col>
        </Row>
        <Row className="mb-2 user-table">
          {/* <Col md={2}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col> */}
          <Col md={3}>
            <label>
              <b>User Name</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter UserName"
              step="1"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>Trading Plan</b>
            </label> 
            <Form.Select value={tradingPlanId} onChange={(e) => setTradingPlanId(e.target.value)}>
              <option value="">Select Plan</option>
              {
                !!tradingPlans && tradingPlans?.map((plan, index) => <option key={index} value={plan?.id}>{plan?.name}</option>)
              }
            </Form.Select>
          </Col>
          <Col md={2}>
            <label>
              <b>Status</b>
            </label> 
            <Form.Select value={status} onChange={(e) => setStatus(/true/.test(e.target.value))}>
              <option value="">Select Status</option>
              <option value={true}>Active</option>
              <option value={false}>InActive</option>
            </Form.Select>
          </Col>
          <Col md={1}>
            <Button className="mt-4 w-50 p-1" variant="success" type="submit" onClick={handleSubmit}>
            <i className="bi bi-search"></i>
            </Button>
            <Button className="mt-4 w-50 p-1" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
            <i className="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
          <Col md={4} className="text-align-end">
            <Button
              onClick={async() => {
                const resp = window.confirm("Are you sure Automatically transfer money.")
                if(resp){
                 const apiResp = await dispatch(autoMoneyTransfer())
                 if(apiResp?.payload?.status){
                  window.location.reload();
                 }
                }
              }}
              className="mt-4 w-50 p-1" variant="primary">
              Transfer Money
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Date</th>
                <th>Username</th>
                <th>Trading PlanId</th>
                <th>AmountGiven</th>
                <th>TotalRevenue</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!userSubscriptionData &&
                userSubscriptionData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{data?.userid?.username}</td>
                      <td>{data?.tradingPlanId?.name}</td>
                      <td>{data?.amountGiven}</td>
                      <td>{data?.totalRevenue}</td>
                      <td>{data?.status ? <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}</td>
                      <td>
                        <UserSubscriptionAction 
                        id={data?.id}
                        status={data?.status}
                        api={updateUserSubscription}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!userSubscriptionDataBlank && userSubscriptionDataBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getUserSubscription(
                {page:currentPage + 1, 
                  amount, 
                  // end:endDate, 
                  // start:startDate, 
                  status, 
                  userid: userName, subadmin}
                ))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default UserSubscriptionList;
