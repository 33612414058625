import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getPayOut, getTradingPlans } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  dailyIncome: yup.number().required(),
  price: yup.number().required(),
  maxIncome: yup.number().required(),
  status: yup.string().required(),
  maxUses: yup.string().required(),
  planImage: yup.mixed(),
});

function TradingPlanAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    btnTitle,
    btnFloat,
    id,
    name,
    dailyIncome,
    maxIncome,
    planImage,
    price,
    maxUses,
    status,
    api,
  } = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
        {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update"}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiresult = await dispatch(api(values));
            if (apiresult?.payload?.status) {
              resetForm({ values: "" });
              dispatch(getTradingPlans());
              setShow(false);
            }
          }}
          initialValues={{
            id,
            name,
            dailyIncome,
            maxIncome,
            planImage,
            price,
            maxUses,
            status,
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Enter Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>DailyIncome</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="DailyIncome"
                      name="dailyIncome"
                      value={values.dailyIncome}
                      onChange={handleChange}
                      isInvalid={!!errors.dailyIncome}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dailyIncome}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Max Uses</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Max Uses"
                      name="maxUses"
                      value={values.maxUses}
                      onChange={handleChange}
                      isInvalid={!!errors.maxUses}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.maxUses}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Max Income</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Max Income"
                      name="maxIncome"
                      value={values.maxIncome}
                      onChange={handleChange}
                      isInvalid={!!errors.maxIncome}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.maxIncome}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        setFieldValue("status", /true/.test(e.target.value))
                      }
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>InActive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label for="planImage">Images</Form.Label>
                    <Form.Control
                      id="planImage"
                      type="file"
                      name="planImage"
                      onChange={(event) => {
                        const resumeFile = event.target.files[0];
                        setFieldValue("planImage", resumeFile);
                      }}
                      isInvalid={!!errors.planImage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.planImage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default TradingPlanAction;
