import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Table, Col, Row} from "react-bootstrap";
import Header from "../../Components/Header";
import { addPayOut, addTradingPlans, getPayOut, getTradingPlans, updatePayOut, updateTradingPlan } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import TradingPlanAction from "./TradingPlanAction";

const TradingPlanList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getTradingPlans());
  }, [navigate, dispatch]);
  const { tradingPlans } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Trading Plan</h3>
          </Col>
          <Col md={6}>
            <TradingPlanAction api={addTradingPlans} btnTitle={"Add"} btnFloat={"float-right"}/>
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Name</th>
                <th>Max Uses</th>
                <th>Price</th>
                <th>Max Income</th>
                <th>Daily Income</th>
                <th>Plan Image</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!tradingPlans &&
                tradingPlans?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.name}</td>
                      <td>{data?.maxUses}</td>
                      <td>{data?.price}</td>
                      <td>{data?.maxIncome}</td>
                      <td>{data?.dailyIncome}</td>
                      <td><img className="w-50" src={data?.planImage} alt={data?.name}/></td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td><TradingPlanAction api={updateTradingPlan} planImage={data?.planImage} maxUses={data?.maxUses} dailyIncome={data?.dailyIncome} maxIncome={data?.maxIncome} id={data?.id} price={data?.price} name={data?.name} status={data?.status}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TradingPlanList;
